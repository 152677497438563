import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import companyService from '../../../services/companyService'
import { Company } from '../../../models/Company'

class CompanyActions {
  loadCompany = createAsyncThunk(
    'company/loadCompany',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await companyService.subscribeToCompany({
        companyId,
        onCompanyUpdated: (company: Company) => {
          dispatch(this.updateCompany(company))
        },
      })
      return response
    },
  )
  updateCompany = createAction('company/updateCompany', (company: Company) => ({
    payload: company,
  }))
  cancelSubscription = createAction('company/cancelSubscription', () => ({
    payload: null,
  }))
  purchaseSubscription = createAction('company/purchaseSubscription', () => ({
    payload: null,
  }))
}

export default new CompanyActions()
