import { createSlice } from '@reduxjs/toolkit'
import { TemplatePendingApproval } from '../../../models/TemplatePendingApproval'
import authActions from '../auth/actions'
import actions from './actions'

export interface TemplatesPendingApprovalState {
  templatesPendingApproval: TemplatePendingApproval[]
  error?: string
  status: 'idle' | 'loading' | 'failed'
  currentRequest?: string
}

const initialState: TemplatesPendingApprovalState = {
  templatesPendingApproval: [],
  status: 'idle',
}

export const templatesPendingApprovalSlice = createSlice({
  name: 'templatesPendingApproval',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.addTemplate, (state, action) => {
        const templateIsAlreadyListed =
          state.templatesPendingApproval.findIndex(template => template.id === action.payload.id) >=
          0
        if (!templateIsAlreadyListed) {
          state.templatesPendingApproval.push(action.payload)
        }
      })
      .addCase(actions.removeTemplate, (state, action) => {
        const indexOfTemplate = state.templatesPendingApproval.findIndex(
          template => template.id === action.payload.id,
        )
        const templateIsAlreadyListed = indexOfTemplate >= 0
        if (templateIsAlreadyListed) {
          state.templatesPendingApproval.splice(indexOfTemplate, 1)
        }
      })
      .addCase(actions.loadTemplatesPendingApproval.pending, (state, action) => {
        state.status = 'loading'
        state.currentRequest = action.meta.requestId
      })
      .addCase(actions.loadTemplatesPendingApproval.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.templatesPendingApproval = action.payload.data
          state.error = undefined
        } else {
          action.payload.unsubscribe()
        }
      })
      .addCase(actions.loadTemplatesPendingApproval.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
  },
})

export default templatesPendingApprovalSlice
