import { useEffect, useMemo, ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from '../../../redux/hooks'
import { store } from '../../../redux/store'
import authSelectors from '../../../redux/modules/auth/selectors'

const AwaitEmailVerificationRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loggedIn = useSelector(authSelectors.getLoggedIn)
  const emailVerified = useMemo(() => {
    return authSelectors.getEmailVerified(store.getState())
  }, [])
  useEffect(() => {
    if (!loggedIn) {
      console.info('>>> AwaitEmailVerificationRoute redirect to /login')
      navigate('/login', { state: { from: location.state?.from || location.pathname } })
      return
    }
    if (emailVerified) {
      console.info('>>> AwaitEmailVerificationRoute redirect to /')
      navigate('/')
      return
    }
  }, [loggedIn, emailVerified, navigate, location.state?.from, location.pathname])
  return children
}

export default AwaitEmailVerificationRoute
