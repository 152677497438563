import {
  doc,
  setDoc,
  WriteBatch,
  Transaction,
  serverTimestamp,
  getDocFromServer,
  DocumentSnapshot,
  query,
  where,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { TemplateApproval } from '../../models/TemplateApproval'
import { TemplatePendingApproval } from '../../models/TemplatePendingApproval'
import config from '../../config'
import BaseService from '../BaseService'
import { ISODateString } from '../../models/ISODateString'

class TemplateApprovalService extends BaseService {
  private createTemplateApprovalId = ({
    templateId,
    templateVersion,
  }: {
    templateId: string
    templateVersion: number
  }) => {
    return `${templateId}_${templateVersion}`
  }

  fetchTemplateApproval = async ({
    companyId,
    templateId,
    templateVersion,
    transaction,
  }: {
    transaction?: Transaction
    companyId: string
    templateId: string
    templateVersion: number
  }): Promise<TemplateApproval> => {
    const templateApprovalRef = doc(
      firestore.templateApprovals(companyId),
      this.createTemplateApprovalId({ templateId, templateVersion }),
    )
    let templateApprovalDoc: DocumentSnapshot
    if (transaction) {
      templateApprovalDoc = await transaction.get(templateApprovalRef)
    } else {
      templateApprovalDoc = await getDocFromServer(templateApprovalRef)
    }
    if (!templateApprovalDoc.exists()) {
      throw new Error("TemplateApproval doesn't exist")
    }
    return templateApprovalDoc.data() as TemplateApproval
  }

  createTemplateApproval = ({
    transactionOrBatch,
    companyId,
    templateId,
    templateVersion,
    approvedByUserId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    templateId: string
    templateVersion: number
    approvedByUserId: string
  }): TemplateApproval | Promise<TemplateApproval> => {
    const templateApprovalsRef = firestore.templateApprovals(companyId)
    const id = this.createTemplateApprovalId({ templateId, templateVersion })
    const templateApprovalRef = doc(templateApprovalsRef, id)
    const encodedUrlPart = encodeURIComponent(`/${companyId}/${templateId}_${templateVersion}.pdf`)

    const templateUrl = `${config.bucketBaseUrl}templates${encodedUrlPart}?alt=media`
    const completeTemplateApproval: TemplateApproval = {
      id: templateApprovalRef.id,
      companyId,
      approvedByUserId,
      templateUrl,
      //@ts-expect-error serverTimestamp isnt the data type we use in the app
      approvedTimestamp: serverTimestamp(),
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.set(templateApprovalRef, completeTemplateApproval, { merge: false })
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.set(templateApprovalRef, completeTemplateApproval, { merge: false })
      }
      return {
        ...completeTemplateApproval,
        approvedTimestamp: new Date().toISOString() as ISODateString,
      }
    }
    return setDoc(templateApprovalRef, completeTemplateApproval, { merge: false }).then(() => {
      return {
        ...completeTemplateApproval,
        approvedTimestamp: new Date().toISOString() as ISODateString,
      }
    })
  }

  fetchTemplatesPendingApproval = async ({
    onApprovalRequested,
    onApprovalGranted,
  }: {
    onApprovalRequested: (templatePendingApproval: TemplatePendingApproval) => void
    onApprovalGranted: (templatePendingApproval: TemplatePendingApproval) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.templatesPendingApproval(),
        where('pendingApprovalTemplateId', '!=', null),
      ),
      onAdded: onApprovalRequested,
      onRemoved: onApprovalGranted,
    })
    return {
      data,
      unsubscribe,
    }
  }
}

export default new TemplateApprovalService()
