import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import templateApprovalService from '../../../services/templateApprovalService'
import { TemplatePendingApproval } from '../../../models/TemplatePendingApproval'

class TemplatesPendingApprovalActions {
  loadTemplatesPendingApproval = createAsyncThunk(
    'templatesPendingApproval/loadTemplatesPendingApproval',
    async (params, { dispatch }) => {
      const response = await templateApprovalService.fetchTemplatesPendingApproval({
        onApprovalGranted: (templatePendingApproval: TemplatePendingApproval) => {
          dispatch(this.removeTemplate(templatePendingApproval))
        },
        onApprovalRequested: (templatePendingApproval: TemplatePendingApproval) => {
          dispatch(this.addTemplate(templatePendingApproval))
        },
      })
      return response
    },
  )
  addTemplate = createAction(
    'templatesPendingApproval/addTemplate',
    (templatePendingApproval: TemplatePendingApproval) => ({
      payload: templatePendingApproval,
    }),
  )
  removeTemplate = createAction(
    'templatesPendingApproval/removeTemplate',
    (templatePendingApproval: TemplatePendingApproval) => ({
      payload: templatePendingApproval,
    }),
  )
}

export default new TemplatesPendingApprovalActions()
