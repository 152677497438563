import {
  getFirestore,
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  Timestamp,
} from 'firebase/firestore'
import { User } from '../models/User'
import { UserAuthTrigger } from '../models/UserAuthTrigger'
import { Company } from '../models/Company'
import { Template } from '../models/Template'
import { TemplateApproval } from '../models/TemplateApproval'
import { TemplatePendingApproval } from '../models/TemplatePendingApproval'
import { CompanyPlanningRecord } from '../models/CompanyPlanningRecord'
import { ISODateString } from '../models/ISODateString'
import { PeriodReport } from '../models/PeriodReport'
import { CompanyRankReport } from '../models/CompanyRankReport'
import { PlanningRecord } from '../models/PlanningRecord'
import { DeadLetterEvent } from '../models/DeadLetterEvent'
import { TransactionalOutboxEvent } from '../models/TransactionalOutboxEvent'
import _ from 'lodash'
import { PendingDeadLetterCounter } from '../models/PendingDeadLetterCounter'

type DateKeys<T> = {
  [K in keyof T]: T[K] extends Date | ISODateString | null
    ? K
    : T[K] extends object
      ? `${K & string}.${DateKeys<T[K]> & string}`
      : never
}[keyof T]

type WithRequiredFields = {
  id: string
}
class Firestore {
  private converter = <T extends WithRequiredFields, F = WithRequiredFields>({
    dateFields,
    fromFirestore,
  }: {
    dateFields?: DateKeys<T>[]
    fromFirestore?: (data: F) => T
  } = {}): FirestoreDataConverter<T> => ({
    toFirestore: (data: WithFieldValue<T>): DocumentData => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = data as T
      if (dateFields) {
        for (const dateField of dateFields) {
          const value = _.get(rest, dateField)
          if (value && (typeof value === 'string' || value instanceof String)) {
            _.set(rest, dateField, new Date(value as string))
          }
        }
      }
      return rest as DocumentData
    },
    fromFirestore: (snap: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
      const data = snap.data()
      if (dateFields) {
        for (const dateField of dateFields) {
          const value = _.get(data, dateField)
          if (value && typeof value === 'object' && value instanceof Timestamp) {
            _.set(data, dateField, (<Timestamp>value).toDate().toISOString() as ISODateString)
          }
        }
      }
      if (fromFirestore) {
        return fromFirestore({ id: snap.id, ...data } as F)
      }
      return { id: snap.id, ...data } as T
    },
  })
  private collection = <T extends WithRequiredFields>(
    converter: FirestoreDataConverter<T>,
    path: string,
    ...pathSegments: string[]
  ): CollectionReference<T> =>
    collection(getFirestore(), path, ...pathSegments).withConverter(converter)
  templates = (companyId: string): CollectionReference<Template> =>
    this.collection<Template>(this.converter(), 'companies', companyId, 'templates')
  planningRecords = (): CollectionReference<PlanningRecord> =>
    this.collection<PlanningRecord>(
      this.converter({
        dateFields: ['createdTimestamp', 'decisionIssuedDate'],
      }),
      'planningRecords',
    )
  deadLetterEvents = (): CollectionReference<DeadLetterEvent> =>
    this.collection<DeadLetterEvent>(
      this.converter({
        dateFields: [
          'createdTimestamp',
          'event.payload.planningRecord.createdTimestamp',
          'event.payload.planningRecord.decisionIssuedDate',
        ],
      }),
      'deadLetterEvents',
    )
  transactionalOutboxEvents = (): CollectionReference<TransactionalOutboxEvent> =>
    this.collection<TransactionalOutboxEvent>(
      this.converter({
        dateFields: [
          'createdTimestamp',
          'event.payload.planningRecord.createdTimestamp',
          'event.payload.planningRecord.decisionIssuedDate',
        ],
      }),
      'transactionalOutbox',
    )
  companyPlanningRecords = (companyId: string): CollectionReference<CompanyPlanningRecord> =>
    this.collection<CompanyPlanningRecord>(
      this.converter({ dateFields: ['processedTimestamp', 'decisionIssuedDate'] }),
      'companies',
      companyId,
      'companyPlanningRecords',
    )
  companyReports = (companyId: string): CollectionReference<PeriodReport> =>
    this.collection<PeriodReport>(this.converter(), 'companies', companyId, 'reports')
  templateApprovals = (companyId: string): CollectionReference<TemplateApproval> =>
    this.collection<TemplateApproval>(
      this.converter({ dateFields: ['approvedTimestamp'] }),
      'companies',
      companyId,
      'templateApprovals',
    )
  users = (): CollectionReference<User> => this.collection<User>(this.converter(), 'users')
  companies = (): CollectionReference<Company> =>
    this.collection<Company>(this.converter(), 'companies')
  reports = (): CollectionReference<PeriodReport> =>
    this.collection<PeriodReport>(this.converter(), 'reports')
  pendingDeadLetterCounters = (): CollectionReference<PendingDeadLetterCounter> =>
    this.collection<PendingDeadLetterCounter>(this.converter(), 'counters')
  companyRankReports = (): CollectionReference<CompanyRankReport> =>
    this.collection<CompanyRankReport>(this.converter(), 'companyRankReports')
  templatesPendingApproval = (): CollectionReference<TemplatePendingApproval> =>
    this.collection<TemplatePendingApproval>(
      this.converter<TemplatePendingApproval, Company>({
        fromFirestore: data => {
          const mapped: TemplatePendingApproval = {
            id: data.pendingApprovalTemplateId as string,
            version: data.pendingApprovalTemplateVersion as number,
            companyId: data.id,
          }
          return mapped
        },
      }),
      'companies',
    )
  userAuthTriggers = (): CollectionReference<UserAuthTrigger> =>
    this.collection<UserAuthTrigger>(
      this.converter({ dateFields: ['updateRequested'] }),
      'userAuthTriggers',
    )
}
export default new Firestore()
