import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'

const HeaderLayout = ({
  login,
  logout,
  isLoggedIn,
}: {
  login: () => void
  logout: () => void
  isLoggedIn: boolean
}) => {
  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" variant="outlined" elevation={0}>
          <Toolbar variant="dense">
            <Box sx={{ flex: 1 }} />
            <Button color="inherit" onClick={isLoggedIn ? logout : login}>
              {isLoggedIn ? 'Logout' : 'Login'}
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </Box>
  )
}

export default HeaderLayout
