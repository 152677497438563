import { useEffect, ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from '../../../redux/hooks'
import authSelectors from '../../../redux/modules/auth/selectors'
import { Role } from '../../../models/Role'

const PrivateRoute = ({
  children,
  allowedRoles = [],
}: {
  children: ReactElement
  allowedRoles: Role[]
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loggedIn = useSelector(authSelectors.getLoggedIn)
  const emailVerified = useSelector(authSelectors.getEmailVerified)
  const role = useSelector(authSelectors.getRole)
  useEffect(() => {
    if (!loggedIn) {
      console.info('>>> PrivateRoute redirect to /login')
      navigate('/login', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
    if (!emailVerified) {
      console.info('>>> PrivateRoute redirect to /await-email-verification')
      navigate('/await-email-verification', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
    if (allowedRoles.length && role && !allowedRoles.includes(role)) {
      console.info('>>> PrivateRoute redirect to /access-denied')
      navigate('/access-denied', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
  }, [
    loggedIn,
    emailVerified,
    role,
    allowedRoles,
    navigate,
    location.state?.from,
    location.pathname,
  ])
  return children
}

export default PrivateRoute
